import React from 'react'
import styled from '@emotion/styled'

const CenteredDiv = styled.div`
  text-align: center;
  margin: 5rem 0;
`

const Hero = ({ className, title, description, actionButton, children }) => {
  return (
    <CenteredDiv className={className}>
      <h2>{title}</h2>
      <p>{description}</p>

      {children}

      <br />
      {actionButton}
    </CenteredDiv>
  )
}

export default Hero
