import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { CenterTextDiv, VerticalSpace } from '../components/common'
import Button from '../components/button'
import Icon from '../components/icon'
import Hero from '../components/hero'
import ScrollableImages from '../components/scrollableImages'

const DownloadButton = Button.withComponent('a')

const BookingCompleted = ({ data }) => {
  const image4 = data.image4.childImageSharp.fluid
  const image5 = data.image5.childImageSharp.fluid
  const image6 = data.image6.childImageSharp.fluid

  const coursePlanDescription = data.coursePlan.description
  const coursePlanDownloadUrl = data.coursePlan.file.url


  return (
    <Layout>
      <SEO title="Vielen Dank!" />
      <CenterTextDiv>
        <h2>Buchungsanfrage versendet</h2>
        <p>
          Vielen Dank für deine Anfrage. Du erhälst eine Buchungsbestätigung,
          sowie die Rechnung innerhalb der nächsten Tage per Mail. Solltest Du
          einen Gutschein haben, kann dieser bei Vorlage am ersten Kurstag der
          Barzahlung gegen gerechnet werden. Für weitere Fragen stehen wir dir
          jederzeit zur Verfügung.
          <br />
          <strong>Wir freuen uns auf Dich!</strong>
        </p>

        <p>
          <strong>
            Alles auf einen Blick! Lade Dir die aktuelle Kursübersicht herunter.
          </strong>
        </p>
        <DownloadButton
          href={coursePlanDownloadUrl}
          target="_blank"
        >
          <div>Jetzt downloaden</div>
          <Icon style={{ marginLeft: 10 }} name="pdf" width={40} />
        </DownloadButton>
        

        <p>{coursePlanDescription}</p>

        <VerticalSpace />

        <Hero
          actionButton={<Button to="/blog">Mehr entdecken</Button>}
          description="Tauche ein in die Welt der Yogis und entdecke interessante Themen:"
        >
          <ScrollableImages
            data={[
              { image: image4, title: 'Yogische Ernährung' },
              { image: image5, title: 'Nachhaltig Leben' },
              { image: image6, title: 'Karma Yoga' },
            ]}
          />
        </Hero>
      </CenterTextDiv>
    </Layout>
  )
}

export default BookingCompleted

export const query = graphql`
  query {
    coursePlan: contentfulAsset(
      contentful_id: { eq: "5Mw5xKbDg6V30E2MMAGJlH" }
    ) {
      id
      file {
        url
      }
      description
    }

    image4: file(relativePath: { eq: "small/05_LP_topic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(relativePath: { eq: "small/06_LP_topic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image6: file(relativePath: { eq: "small/07_LP_topic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
